<template>
  <b-modal
    :id="`modal-result-train-check-birthday-pax-${itineraryIndex}`"
    :title="$t('train.search.result.dob')"
    title-class="font-medium-4 fw-600 text-primary"
    header-bg-variant="light-primary"
    centered
    @hide="hideModal"
    @show="showModal"
  >
    <div v-if="['CHILD'].includes(paxType)">
      <div>
        {{ $t('train.search.result.noTicketForUnder6') }}
      </div>
      <div>
        {{ $t('train.search.result.childDiscount') }}
      </div>
    </div>
    <div v-else>
      {{ $t('train.search.result.seniorDiscount') }}
    </div>
    <div class="my-1">
      <b-form-group>
        <template #label>
          {{ $t('reservation[\'DoB\']') }} <span class="text-danger">(*)</span>
        </template>
        <flat-pickr
          v-model="birthday"
          class="d-none"
        />
        <div
          class="form-control p-0"
          :class="invalid ? 'is-invalid' : ''"
        >
          <flat-pickr
            id="passenger-birthday"
            v-model="birthday"
            :name="$t('flight.dob')"
            class="form-control px-50 py-0"
            :config="{
                  ...configFlatPickr,
                  altInputClass: 'form-control input px-50 py-0 h-100 border-0',
            }"
            :placeholder="$t('flight.dob')"
          />
        </div>
      </b-form-group>
    </div>
    <div
      v-if="invalid"
      class="text-danger fw-700"
    >
      {{ $t('train.search.result.invalidDiscountAge') }}
    </div>
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end w-100">
        <b-button
          size="md"
          variant="outline-secondary"
          class="center mr-2"
          @click="cancel()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-success border-right-0"
          @click="handleSubmit"
        >
          <div class="d-flex-center px-25">
            {{ $t('train.search.result.confirm') }}
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api'
import {
  BButton, BFormGroup, BModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import VueI18n from '@/libs/i18n'
import YearDropdownPlugin from '@/libs/flatpickr/yearDropdownPlugin'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,

    flatPickr,
  },
  props: {
    paxType: {
      type: String,
      default: () => '',
    },
    ticketIdVal: {
      type: Number,
      default: 0,
    },
    paxTargetCode: {
      type: Number,
      default: 0,
    },
    itineraryIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      getSearchPayloadArray,
      delay,
    } = useTrainHandle()

    const { paxType, paxTargetCode } = toRefs(props)
    const invalid = ref(false)
    const birthday = ref('')

    async function handleSubmit() {
      const refBirthday = new Date(birthday.value)
      const type = paxType.value
      const departDateValue = new Date(getSearchPayloadArray.value[(getSearchPayloadArray.value?.length - 1) || 0].departDate)

      if (type === 'CHILD') {
        const infantDepartDate = new Date(departDateValue)
        infantDepartDate.setFullYear(infantDepartDate.getFullYear() - 6)
        const childDepartDate = new Date(departDateValue)
        childDepartDate.setFullYear(childDepartDate.getFullYear() - 10)

        const validChild = refBirthday >= childDepartDate
        const warningChild = refBirthday >= infantDepartDate && refBirthday >= childDepartDate

        if (!validChild) {
          invalid.value = true
        } else if (warningChild) {
          this.$bvModal
            .msgBoxConfirm(this.$t('train.search.result.childUnder6NoTicket'), {
              title: this.$t('modal.confirm'),
              size: 'sm',
              okVariant: 'success',
              okTitle: this.$t('modal.yes'),
              cancelTitle: this.$t('modal.no'),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: true,
              centered: true,
            })
            .then(value => {
              if (value) {
                emit('handleUpdate', { ticketId: props.ticketIdVal, passengerTargetCode: paxTargetCode.value, birthday: birthday.value })
                this.$bvModal.hide(`modal-result-train-check-birthday-pax-${props.itineraryIndex}`)
              } else {
                this.$bvModal.hide(`modal-result-train-check-birthday-pax-${props.itineraryIndex}`)
              }
            })
            .catch(err => {
              console.error(err)
            })
        } else {
          emit('handleUpdate', { ticketId: props.ticketIdVal, passengerTargetCode: paxTargetCode.value, birthday: birthday.value })
          await delay(200)
          this.$bvModal.hide(`modal-result-train-check-birthday-pax-${props.itineraryIndex}`)
        }
      } else if (type === 'ELDER') {
        const elderDepartDate = new Date(departDateValue)
        elderDepartDate.setFullYear(elderDepartDate.getFullYear() - 60)
        const validElder = refBirthday <= elderDepartDate
        if (!validElder) {
          invalid.value = true
        } else {
          emit('handleUpdate', { ticketId: props.ticketIdVal, passengerTargetCode: paxTargetCode.value })
          await delay(200)
          this.$bvModal.hide(`modal-result-train-check-birthday-pax-${props.itineraryIndex}`)
        }
      }
    }

    function showModal() { }

    function hideModal() {
      invalid.value = false
      birthday.value = ''
    }

    const locale = computed(() => VueI18n.locale).value
    const configFlatPickr = ref({
      plugins: [
        new YearDropdownPlugin({
          date: new Date(),
          yearStart: 100,
          yearEnd: 0,
        }),
      ],
      dateFormat: 'Y-m-d',
      minDate: '1.1.1920',
      // maxDate: 'today',
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    })

    return {
      configFlatPickr,
      birthday,
      invalid,
      showModal,
      hideModal,
      handleSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

body.modal-open {
  div.flatpickr-calendar.open {
    div.flatpickr-current-month select.flatpickr-monthDropdown-months {
         padding-top: 6px;
         margin-right: 4px;
         width: 50px;
      }
  }
}
</style>
