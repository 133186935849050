var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-result-train-check-birthday-pax-".concat(_vm.itineraryIndex),
      "title": _vm.$t('train.search.result.dob'),
      "title-class": "font-medium-4 fw-600 text-primary",
      "header-bg-variant": "light-primary",
      "centered": ""
    },
    on: {
      "hide": _vm.hideModal,
      "show": _vm.showModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex justify-content-end w-100"
        }, [_c('b-button', {
          staticClass: "center mr-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-success border-right-0",
          on: {
            "click": _vm.handleSubmit
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.confirm')) + " ")])])], 1)];
      }
    }])
  }, [['CHILD'].includes(_vm.paxType) ? _c('div', [_c('div', [_vm._v(" " + _vm._s(_vm.$t('train.search.result.noTicketForUnder6')) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('train.search.result.childDiscount')) + " ")])]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('train.search.result.seniorDiscount')) + " ")]), _c('div', {
    staticClass: "my-1"
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('reservation[\'DoB\']')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])];
      },
      proxy: true
    }])
  }, [_c('flat-pickr', {
    staticClass: "d-none",
    model: {
      value: _vm.birthday,
      callback: function callback($$v) {
        _vm.birthday = $$v;
      },
      expression: "birthday"
    }
  }), _c('div', {
    staticClass: "form-control p-0",
    class: _vm.invalid ? 'is-invalid' : ''
  }, [_c('flat-pickr', {
    staticClass: "form-control px-50 py-0",
    attrs: {
      "id": "passenger-birthday",
      "name": _vm.$t('flight.dob'),
      "config": Object.assign({}, _vm.configFlatPickr, {
        altInputClass: 'form-control input px-50 py-0 h-100 border-0'
      }),
      "placeholder": _vm.$t('flight.dob')
    },
    model: {
      value: _vm.birthday,
      callback: function callback($$v) {
        _vm.birthday = $$v;
      },
      expression: "birthday"
    }
  })], 1)], 1)], 1), _vm.invalid ? _c('div', {
    staticClass: "text-danger fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.invalidDiscountAge')) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }